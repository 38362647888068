
  import {
    defineComponent,
    ref,
    computed,
    onMounted,
    reactive,
    toRefs,
  } from "vue";
  import { useStore } from "vuex";
  
  export default defineComponent({
    setup() {
      const form = reactive({
        id: "",
        sold_to: "",
        retailer_name: "",
      });
      const form_action = ref('save')
      const visible = ref(false);
      const store = useStore();
      const fields = ref([
        {
          title: "Site Name",
          dataIndex: "site.site_name",
        },
        {
          title: "Tank Name",
          dataIndex: "site_product.tank.name",
        },
        {
          title: "Product Name",
          dataIndex: "site_product.product.product_name",
        },
        {
          title: "Log Type",
          dataIndex: "log_type",
        },
        {
          title: "Payload",
          slots: { customRender: 'inventory_logs_payload' },
          dataIndex: "payload",
        },
      ]);
  
      const index = (payload={page: 1}) => {
        store.dispatch("InventoryLogs/index", payload);
      };
  
  
      const filterRecord = (payload: any) => {
        index(payload);
      }
  
      const items = computed(() => store.getters["InventoryLogs/items"]);
      const loading = computed(() => store.getters["InventoryLogs/status"]);
  
      onMounted(index);
  
      return {
        loading,
        items,
        fields,
        visible,
        form,
        filterRecord
      };
    },
  });
  